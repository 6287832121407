import React from "react"
import * as footerStyles from "./footer.module.css"
import { Row, Col, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faLinkedin,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <footer>
      <Container fluid className={footerStyles.footerStyle}>
        <Row>
          <Col className={footerStyles.colStyle}>
            <div style={{ margin: "0 auto" }}>
              <p className={footerStyles.spamMe}>Stalk me at</p>
              <a
                href="https://www.facebook.com/swetha.naik.12/"
                className={footerStyles.social}
              >
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/in/swetharnaik-mec/"
                className={footerStyles.social}
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/vivid_wanderer/"
                className={footerStyles.social}
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </div>
          </Col>
          <Col className={footerStyles.colStyle}>
            <p className={footerStyles.spamMe}>
              Spam me at
            </p>
            <div className={footerStyles.emailId}>
              swetharnaik.mec
              @gmail.com
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
