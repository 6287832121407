import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBriefcase
} from "@fortawesome/free-solid-svg-icons"
import "./header.css"

const Header = ({ siteTitle }) => (
  <header className="bgCustom">
    <Navbar className="navBarBlack" expand="md" variant="custom">
      <Navbar.Brand href="/" className="navBrand" >{siteTitle}</Navbar.Brand>
      <Navbar.Toggle className="navBarToggle" aria-controls="responsive-navbar-nav">
        <FontAwesomeIcon icon={faBriefcase} size="lg" />
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav as="ul" className="ml-auto">
          <Nav.Item as="li">
            <Link to="/" className="nav-link" activeClassName="active">Home</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/myposts" className="nav-link" activeClassName="active">Posts</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/about" className="nav-link" activeClassName="active">About</Link>
          </Nav.Item>
          {/* <Nav.Item as="li">
            <Link to="/contact" className="nav-link" activeClassName="active">Contact</Link>
          </Nav.Item> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
